import React from 'react';
import { X } from 'lucide-react';

const TopicOverview = ({ topic, onClose, isDarkMode }) => {
  if (!topic) return null;

  return (
    <div className={`h-full overflow-auto p-4 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{topic.title} - Overview</h2>
        <button
          onClick={onClose}
          className={`p-2 rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
          aria-label="Close overview"
        >
          <X size={24} />
        </button>
      </div>
      <p className="mb-4">{topic.description}</p>
      <h3 className="text-xl font-semibold mb-2">Steps:</h3>
      <ol className="list-decimal list-inside space-y-2">
        {topic.steps.map((step, index) => (
          <li key={index} className={`p-2 rounded ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}>
            {step.title}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TopicOverview;
