import React from 'react';

const ProgressBar = ({ percentage, currentStep, totalSteps, isDarkMode }) => {
  return (
    <div className={`w-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-4 overflow-hidden`}>
      <div 
        className="h-full bg-blue-500 transition-all duration-300 ease-in-out flex items-center justify-end pr-2 text-xs text-white font-bold"
        style={{ width: `${percentage}%` }}
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {percentage > 10 && `${Math.round(percentage)}%`}
      </div>
      <div className={`mt-1 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        Step {currentStep} of {totalSteps}
      </div>
    </div>
  );
};

export default ProgressBar;
