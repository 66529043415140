export const getWelcomeMessage = (topicData) => {
  return `Welcome to the ${topicData.title}! This interactive tutorial will help you set up and use a GitHub account. Here's how it works:

1. Steps and Prompts:
   - On the left, you'll see a list of steps in your GitHub learning journey.
   - Each step is also a pre-written question that you can send to me, your AI assistant.
   - Clicking on a step will send its associated question, and I'll provide detailed instructions or information.

2. Learning Process:
   - Start with the first step and work your way down the list.
   - Click on a step to see instructions for that part of the setup process.
   - Follow the instructions and ask any additional questions you have in the chat.

3. Marking Progress:
   - After completing a step, click the checkmark icon next to the send button to mark it as done.
   - This helps you keep track of your progress and tells me you're ready for the next step.

4. Flexibility:
   - Feel free to click on any step, even out of order, if you need specific information.
   - If you're already familiar with some steps, you can mark them as complete and move on.

5. Additional Questions:
   - At any point, you can type your own questions in the chat for more clarification or help.

Remember, I'm here to assist you throughout the process. Don't hesitate to ask for more explanations or examples if something isn't clear.

Are you ready to begin? Click on the first step whenever you're ready to start your GitHub setup journey!`;
};
