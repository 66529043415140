import React from 'react';

const CurrentStepIndicator = ({ currentStep, isDarkMode }) => {
  return (
    <div 
      className={`p-2 text-sm font-semibold ${
        isDarkMode 
          ? 'bg-gray-800 text-blue-300' 
          : 'bg-blue-100 text-blue-800'
      }`}
    >
      Current Step: {currentStep || 'Loading...'}
    </div>
  );
};

export default CurrentStepIndicator;
