import { useState, useEffect, useCallback } from 'react';
import { getWelcomeMessage } from './utils';

const API_BASE_URL = 'https://devops-ai-api.darrelltang.workers.dev';

export const useTopic = () => {
  const [topic, setTopic] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopic = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/topics/github-setup`);
        if (!response.ok) throw new Error(`Failed to fetch topic: ${response.status} ${response.statusText}`);
        const data = await response.json();
        setTopic(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching topic:', error);
        setError(`Failed to load topic: ${error.message}`);
        setLoading(false);
      }
    };

    fetchTopic();
  }, []);

  return { topic, loading, error };
};

export const useProgress = () => {
  const [completedSteps, setCompletedSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/progress/github-setup`);
        if (!response.ok) throw new Error(`Failed to fetch progress: ${response.status} ${response.statusText}`);
        const data = await response.json();
        setCompletedSteps(data.completed_steps);
        setCurrentStep(data.current_step);
      } catch (error) {
        console.error('Error fetching progress:', error);
        setCompletedSteps([]);
        setCurrentStep(0);
      }
    };

    fetchProgress();
  }, []);

  return { completedSteps, setCompletedSteps, currentStep, setCurrentStep };
};

export const useConversation = (topic) => {
  const [messages, setMessages] = useState([]);

  const setInitialMessage = useCallback(() => {
    if (topic) {
      const welcomeMessage = topic.initial_message || getWelcomeMessage(topic);
      setMessages([{ id: Date.now(), text: welcomeMessage, sender: "bot" }]);
    }
  }, [topic]);

  const fetchConversationHistory = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/conversation/github-setup`);
      if (!response.ok) throw new Error(`Failed to fetch conversation: ${response.status} ${response.statusText}`);
      const data = await response.json();
      if (data.messages && data.messages.length > 0) {
        setMessages(data.messages.map(msg => ({
          id: msg.timestamp,
          text: msg.content,
          sender: msg.role
        })));
      } else {
        setInitialMessage();
      }
    } catch (error) {
      console.error('Error fetching conversation:', error);
      setInitialMessage();
    }
  }, [setInitialMessage]);  // Add setInitialMessage as a dependency

  useEffect(() => {
    fetchConversationHistory();
  }, [fetchConversationHistory]);

  return { messages, setMessages, setInitialMessage };
};
