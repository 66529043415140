import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { User, Bot } from 'lucide-react';

const ChatMessage = ({ message, isDarkMode, onSuggestedQuestionClick }) => {
  const components = {
    code({node, inline, className, children, ...props}) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={vscDarkPlus}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} rounded px-1`} {...props}>
          {children}
        </code>
      );
    },
    h1: ({children, ...props}) => <h1 className={`text-2xl font-bold ${isDarkMode ? 'text-blue-300' : 'text-blue-600'} mt-6 mb-4`} {...props}>{children}</h1>,
    h2: ({children, ...props}) => <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-blue-200' : 'text-blue-500'} mt-5 mb-3`} {...props}>{children}</h2>,
    h3: ({children, ...props}) => <h3 className={`text-lg font-medium ${isDarkMode ? 'text-blue-100' : 'text-blue-400'} mt-4 mb-2`} {...props}>{children}</h3>,
    p: ({children, ...props}) => <p className="my-2" {...props}>{children}</p>,
    ul: ({children, ...props}) => <ul className="list-disc pl-6 my-2" {...props}>{children}</ul>,
    ol: ({children, ...props}) => <ol className="list-decimal pl-6 my-2" {...props}>{children}</ol>,
    li: ({children, ...props}) => <li className="my-1" {...props}>{children}</li>,
    a: ({href, children, ...props}) => (
      <a 
        href={href} 
        className={`${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-800'} underline`}
        target="_blank" 
        rel="noopener noreferrer" 
        {...props}
      >
        {children}
      </a>
    ),
    blockquote: ({children, ...props}) => (
      <blockquote className={`border-l-4 ${isDarkMode ? 'border-gray-600 bg-gray-800' : 'border-gray-300 bg-gray-100'} pl-4 py-2 my-2`} {...props}>
        {children}
      </blockquote>
    ),
  };

  const userBgColor = isDarkMode ? 'bg-blue-700' : 'bg-blue-200';
  const botBgColor = isDarkMode ? 'bg-gray-700' : 'bg-gray-200';
  const textColor = isDarkMode ? 'text-white' : 'text-black';

  return (
    <div className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"} mb-4`}>
      <div className={`flex max-w-3xl ${message.sender === "user" ? "flex-row-reverse" : "flex-row"}`}>
        <div className={`flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center ${message.sender === "user" ? "bg-blue-500 ml-2" : "bg-green-500 mr-2"}`}>
          {message.sender === "user" ? <User size={20} color="white" /> : <Bot size={20} color="white" />}
        </div>
        <div className={`p-3 rounded-lg ${message.sender === "user" ? userBgColor : botBgColor} ${textColor}`}>
          <ReactMarkdown components={components}>
            {message.text}
          </ReactMarkdown>
          {message.sender === "assistant" && message.suggestedQuestions && message.suggestedQuestions.length > 0 && (
            <div className="mt-2 flex flex-wrap gap-2">
              <p className="w-full font-semibold">Suggested questions:</p>
              {message.suggestedQuestions.slice(0, 3).map((question, index) => (
                <button
                  key={index}
                  onClick={() => onSuggestedQuestionClick(question)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    isDarkMode 
                      ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                      : 'bg-blue-100 hover:bg-blue-200 text-blue-800'
                  }`}
                >
                  {question}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
