const API_BASE_URL = 'https://devops-ai-api.darrelltang.workers.dev';

const extractMarkdownContent = (responseText) => {
  // First, try to extract content from markdown code block
  const markdownRegex = /```markdown\n([\s\S]*?)\n```/;
  const match = responseText.match(markdownRegex);
  
  if (match) {
    return match[1].trim();
  } else {
    // If no markdown block found, remove any leading text up to the first heading
    const headingRegex = /^[^#\n]*(?=\n?#)/;
    return responseText.replace(headingRegex, '').trim();
  }
};

export const handleSend = async (message) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/chat/github-setup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message }),
    });
    
    if (!response.ok) throw new Error(`Failed to send chat message: ${response.status} ${response.statusText}`);
    
    const data = await response.json();
    console.log('Raw API response:', data);
    
    let processedContent = data.response;
    let suggestedQuestions = data.suggested_questions || [];
    
    // Check if the content is a JSON string and parse it
    try {
      const jsonContent = JSON.parse(processedContent);
      if (jsonContent && typeof jsonContent === 'object') {
        processedContent = jsonContent.response || processedContent;
        suggestedQuestions = jsonContent.suggested_questions || suggestedQuestions;
      }
    } catch (e) {
      // If it's not valid JSON, continue with the original content
      console.log('Response is not in JSON format, using as-is');
    }

    // Extract markdown content and remove redundant prompt
    processedContent = extractMarkdownContent(processedContent);
    
    return {
      response: processedContent,
      suggested_questions: suggestedQuestions
    };
  } catch (error) {
    console.error('Error sending chat message:', error);
    return { 
      response: `Sorry, I encountered an error while processing your message: ${error.message}. Please try again later.`, 
      suggested_questions: [] 
    };
  }
};

export const handleReset = async (setCompletedSteps, setCurrentStep, setMessages) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/reset/github-setup`, { method: 'POST' });
    if (!response.ok) throw new Error(`Failed to reset: ${response.status} ${response.statusText}`);
    
    const progressResponse = await fetch(`${API_BASE_URL}/api/progress/github-setup`);
    if (!progressResponse.ok) throw new Error(`Failed to fetch progress: ${progressResponse.status} ${progressResponse.statusText}`);
    const progressData = await progressResponse.json();
    setCompletedSteps(progressData.completed_steps);
    setCurrentStep(progressData.current_step);
    
    const conversationResponse = await fetch(`${API_BASE_URL}/api/conversation/github-setup`);
    if (!conversationResponse.ok) throw new Error(`Failed to fetch conversation: ${conversationResponse.status} ${conversationResponse.statusText}`);
    const conversationData = await conversationResponse.json();
    setMessages(conversationData.messages.map(msg => ({
      id: msg.timestamp,
      text: extractMarkdownContent(msg.content),
      sender: msg.role
    })));
    
    setMessages(prevMessages => [...prevMessages, { id: Date.now(), text: `Progress and conversation have been reset. Let's start from the beginning!`, sender: "bot" }]);
  } catch (error) {
    console.error('Error resetting:', error);
    setMessages(prevMessages => [...prevMessages, { id: Date.now(), text: `Failed to reset: ${error.message}. Please try again later.`, sender: "bot" }]);
  }
};

export const markStepAsCompleted = async (stepIndex, completedSteps, setCompletedSteps) => {
  const maxCompletedStep = Math.max(...completedSteps, -1);
  
  if (stepIndex !== maxCompletedStep + 1) {
    console.error('Can only complete the next sequential step');
    return;
  }

  try {
    const response = await fetch(`${API_BASE_URL}/api/progress/github-setup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ completed_step: stepIndex }),
    });
    
    if (!response.ok) throw new Error(`Failed to update progress: ${response.status} ${response.statusText}`);
    
    const progressResponse = await fetch(`${API_BASE_URL}/api/progress/github-setup`);
    if (!progressResponse.ok) throw new Error(`Failed to fetch progress: ${progressResponse.status} ${progressResponse.statusText}`);
    const progressData = await progressResponse.json();
    setCompletedSteps(progressData.completed_steps);
  } catch (error) {
    console.error('Error updating progress:', error);
  }
};
