import React from 'react';
import { Book } from 'lucide-react';

const TopicOverviewButton = ({ isDarkMode, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`
        flex items-center px-4 py-2 rounded-lg
        ${isDarkMode 
          ? 'bg-blue-600 hover:bg-blue-700 text-white' 
          : 'bg-blue-100 hover:bg-blue-200 text-blue-800'}
        transition-colors duration-200
      `}
      aria-label="View Topic Overview"
      title="View Topic Overview"
    >
      <Book size={20} className="mr-2" />
      Topic Overview
    </button>
  );
};

export default TopicOverviewButton;
