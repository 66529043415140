import React from 'react';
import { Send, Bot } from 'lucide-react';
import ChatMessage from './ChatMessage';

const ChatWindow = ({
  messages,
  inputText,
  setInputText,
  onSend,
  isWaitingForResponse,
  isDarkMode,
  chatContainerRef,
  onSuggestedQuestionClick
}) => (
  <div className={`flex flex-col h-full ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
    <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 space-y-4">
      {messages.map((message) => (
        <ChatMessage 
          key={message.id}
          message={message} 
          isDarkMode={isDarkMode}
          onSuggestedQuestionClick={onSuggestedQuestionClick}
        />
      ))}
      {isWaitingForResponse && (
        <div className="flex justify-start">
          <div className="flex max-w-2xl">
            <div className="flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center bg-green-500 mr-2">
              <Bot size={20} color="white" />
            </div>
            <div className={`p-3 rounded-lg ${isDarkMode ? "bg-gray-700" : "bg-gray-200"}`}>
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    <div className={`p-4 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-200 border-gray-300'} border-t`}>
      <div className="flex items-center space-x-2">
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && (e.preventDefault(), onSend())}
          className={`flex-1 border ${isDarkMode ? 'border-gray-600 bg-gray-700 text-white' : 'border-gray-300 bg-white text-black'} rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none`}
          placeholder="Type a message..."
          rows="3"
        />
        <button
          onClick={onSend}
          className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <Send size={20} />
        </button>
      </div>
    </div>
  </div>
);

export default ChatWindow;
