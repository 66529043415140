import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTopic, useProgress, useConversation } from '../hooks';
import { handleSend, handleReset, markStepAsCompleted } from '../api';
import Header from './Header';
import ProgressBar from './ProgressBar';
import ChatWindow from './ChatWindow';
import CurrentStepIndicator from './CurrentStepIndicator';
import TopicOverviewButton from './TopicOverviewButton';
import NextStepButton from './NextStepButton';
import TopicOverview from './TopicOverview';
import 'react-markdown';
import 'react-syntax-highlighter';
import 'react-syntax-highlighter/dist/esm/styles/prism';

const GithubSetupGuide = () => {
  const [inputText, setInputText] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [showTopicOverview, setShowTopicOverview] = useState(false);
  const chatContainerRef = useRef(null);

  const { topic, loading: topicLoading, error: topicError } = useTopic();
  const { completedSteps, setCompletedSteps, currentStep, setCurrentStep } = useProgress();
  const { messages, setMessages } = useConversation(topic);

  const addMessageAndGetResponse = useCallback(async (message, isUserMessage = false) => {
    if (isUserMessage) {
      setMessages(prevMessages => [...prevMessages, { id: Date.now(), text: message, sender: "user" }]);
    }
    
    setIsWaitingForResponse(true);
    setInputText("");

    try {
      const response = await handleSend(message);
      if (response && response.response) {
        const botMessage = {
          id: Date.now(),
          text: response.response,
          sender: "assistant",
          suggestedQuestions: response.suggested_questions || []
        };
        setMessages(prevMessages => [...prevMessages, botMessage]);
      }
    } finally {
      setIsWaitingForResponse(false);
    }
  }, [setMessages]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (topic && messages.length === 0) {
      setMessages([{ id: Date.now(), text: topic.initial_message, sender: "assistant" }]);
      
      // Automatically trigger the first step after a short delay
      setTimeout(() => {
        addMessageAndGetResponse(topic.steps[0].prompt);
      }, 1000);
    }
  }, [topic, messages, setMessages, addMessageAndGetResponse]);

  const handleSendWrapper = () => {
    if (inputText.trim() !== "") {
      addMessageAndGetResponse(inputText, true);
    }
  };

  const handleSuggestedQuestionClick = (question) => {
    addMessageAndGetResponse(question, true);
  };

  const handleStepComplete = async () => {
    if (currentStep < (topic?.steps.length || 0) - 1) {
      await markStepAsCompleted(currentStep, completedSteps, setCompletedSteps);
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      addMessageAndGetResponse(topic.steps[nextStep].prompt);
    }
  };

  const handleResetWrapper = async () => {
    await handleReset(setCompletedSteps, setCurrentStep, setMessages);
    if (topic) {
      setMessages([{ id: Date.now(), text: topic.initial_message, sender: "assistant" }]);
      setTimeout(() => {
        addMessageAndGetResponse(topic.steps[0].prompt);
      }, 1000);
    }
  };

  const toggleDarkMode = () => setIsDarkMode(prevMode => !prevMode);

  const handleTopicOverviewClick = () => {
    setShowTopicOverview(true);
  };

  if (topicLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (topicError) {
    return <div className="flex justify-center items-center h-screen text-red-500">{topicError}</div>;
  }

  const progressPercentage = topic ? (completedSteps.length / topic.steps.length) * 100 : 0;

  return (
    <div className={`flex flex-col h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <Header 
        title={topic?.title || 'GitHub Setup Guide'} 
        isDarkMode={isDarkMode} 
        onReset={handleResetWrapper} 
        onToggleDarkMode={toggleDarkMode}
      />
      <ProgressBar 
        percentage={progressPercentage}
        currentStep={currentStep + 1}
        totalSteps={topic?.steps.length || 0}
        isDarkMode={isDarkMode}
      />
      <CurrentStepIndicator 
        currentStep={topic?.steps[currentStep]?.title} 
        isDarkMode={isDarkMode} 
      />
      <div className="flex-1 overflow-hidden">
        {showTopicOverview ? (
          <TopicOverview 
            topic={topic} 
            onClose={() => setShowTopicOverview(false)}
            isDarkMode={isDarkMode}
          />
        ) : (
          <ChatWindow 
            messages={messages} 
            inputText={inputText} 
            setInputText={setInputText} 
            onSend={handleSendWrapper} 
            isWaitingForResponse={isWaitingForResponse} 
            isDarkMode={isDarkMode} 
            chatContainerRef={chatContainerRef}
            onSuggestedQuestionClick={handleSuggestedQuestionClick}
          />
        )}
      </div>
      <div className="flex justify-between p-2">
        <TopicOverviewButton 
          isDarkMode={isDarkMode} 
          onClick={handleTopicOverviewClick}
        />
        <NextStepButton 
          onClick={handleStepComplete}
          nextStepTitle={topic?.steps[currentStep + 1]?.title || 'Finish'}
          isDarkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

export default GithubSetupGuide;
