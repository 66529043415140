import React from 'react';
import GithubSetupGuide from './components/GithubSetupGuide';

function App() {
  return (
    <div className="App">
      <GithubSetupGuide />
    </div>
  );
}

export default App;
