import React from 'react';
import { RefreshCw, Moon, Sun } from 'lucide-react';

const Header = ({ title, isDarkMode, onReset, onToggleDarkMode }) => {
  return (
    <div className={`p-4 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} flex justify-between items-center`}>
      <h1 className="text-2xl font-bold">{title}</h1>
      <div className="flex items-center space-x-4">
        <button 
          onClick={onReset} 
          className={`p-2 rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
          aria-label="Reset progress"
          title="Reset progress"
        >
          <RefreshCw size={20} />
        </button>
        <button 
          onClick={onToggleDarkMode} 
          className={`p-2 rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
          aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
          title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
        >
          {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
        </button>
      </div>
    </div>
  );
};

export default Header;
