import React from 'react';
import { ArrowRight } from 'lucide-react';

const NextStepButton = ({ onClick, nextStepTitle, isDarkMode }) => {
  return (
    <button
      onClick={onClick}
      className={`
        flex items-center px-4 py-2 rounded-lg
        ${isDarkMode 
          ? 'bg-green-600 hover:bg-green-700 text-white' 
          : 'bg-green-100 hover:bg-green-200 text-green-800'}
        transition-colors duration-200
      `}
      aria-label={`Next Step: ${nextStepTitle}`}
      title={`Next Step: ${nextStepTitle}`}
    >
      Next: {nextStepTitle}
      <ArrowRight size={20} className="ml-2" />
    </button>
  );
};

export default NextStepButton;
